import React, { useLayoutEffect, useMemo, useState } from "react";
import "leaflet/dist/leaflet.css";
import { Box, Stack } from "@mui/material";

import PointDetailDrawer from "../../TourDetail/PointDetailDrawer/PointDetailDrawer";
import { sortByPriority } from "../../../../utils/sortByPriority";
import { PlotPoint } from "../CreateTourMap/CreatePlotMap/PlotPoint/PlotPoint";

import styles from "./TourPreview.module.css";
import { MapContainer } from "../../../MapKit/MapContainer/MapContainer";
import { Marker } from "../../../MapKit/Marker/Marker";
import { DefaultMapMarker } from "../MapMarker/MapMarker";
import { MapRoute } from "../../../MapKit/MapRoute/MapRoute";
import { FitBoundsOnMap } from "../../../MapKit/FitBoundsOnMap/FitBoundsOnMap";
import { ImagePreviewModal } from "../../../Modals/ImagePreviewModal/ImagePreviewModal";

export const TourPreview = ({ points, step, routeRetrieveData }: any) => {
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [openPointDetail, setOpenPointDetail] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

  const [allPoints, setAllPoints] = useState<any>([]);

  useLayoutEffect(() => {
    const allPoints = points?.reduce((acc: number[], item: any) => {
      const itemPoints = item?.points
        ?.map((point: any) => {
          if (point?.coordinate?.coordinates[0]) {
            return [
              point.coordinate.coordinates[1],
              point.coordinate.coordinates[0],
            ];
          }
          return null;
        })
        .filter(Boolean);

      return [...acc, ...(itemPoints || [])];
    }, []);

    setAllPoints(allPoints);
  }, [points]);

  const currentDataRoutePartsPlots = routeRetrieveData?.data?.route_parts
    ?.sort(sortByPriority)
    ?.map((item: any) => {
      if (item?.type_of_route === "plot") {
        return item?.plot?.check_points?.map((point: any) => [
          point?.coordinate?.coordinates[1],
          point?.coordinate?.coordinates[0],
        ]);
      }
    });

  const currentAllPointsData = useMemo(() => {
    if (routeRetrieveData?.data?.route_parts?.length > 0) {
      return routeRetrieveData?.data?.route_parts
        .sort(sortByPriority)
        ?.map((item: any, index: any) => ({
          id: item?.id,
          type: item?.type_of_route,
          index: index + 1,
          coord:
            item?.type_of_route === "check_point"
              ? item?.check_point?.coordinate?.coordinates?.reverse()
              : item?.plot?.check_points
                  ?.sort(sortByPriority)
                  ?.map((item: any) => [
                    item?.coordinate?.coordinates?.[0],
                    item?.coordinate?.coordinates?.[1],
                  ]),
        }));
    }
  }, [routeRetrieveData]);

  console.log(currentDataRoutePartsPlots)

  return (
    <>
      {allPoints?.length > 0 && (
        <MapContainer
          style={{
            position: "absolute",
            height: "100%",
            width: "calc(100%)",
            transform: "translateX(-232px)",
          }}
          center={allPoints[0]}
          zoom={10}
        >
          <FitBoundsOnMap
            points={allPoints}
            params={
              allPoints?.length === 1
                ? {
                    maxZoom: 10,
                  }
                : { padding: { top: 128, bottom: 128, left: 365, right: 368 } }
            }
          />
          {selectedPoint?.id && (
            <FitBoundsOnMap
              points={
                selectedPoint?.type_of_route === "check_point"
                  ? [selectedPoint?.check_point?.coordinate?.coordinates]
                  : selectedPoint?.plot?.check_points?.map(
                      (item: { coordinate: any }) => [
                        item?.coordinate?.coordinates[1],
                        item?.coordinate?.coordinates[0],
                      ]
                    )
              }
              params={
                selectedPoint?.type_of_route === "check_point"
                  ? {
                      maxZoom: 18,
                    }
                  : {
                      padding: { top: 128, bottom: 128, left: 365, right: 368 },
                    }
              }
            />
          )}
          {currentDataRoutePartsPlots
            ?.filter((routePart: any) => !!routePart)
            ?.map((item: any, index: number) => {
              return (
                <MapRoute
                  points={item}
                  isDashed={false}
                  id={index}
                  onRouteClick={() => {
                    setSelectedPoint(
                      routeRetrieveData?.data?.route_parts[index]
                    );
                    setOpenPointDetail(true);
                  }}
                />
              );
            })}
          <MapRoute
            buildAllRoutes={true}
            points={allPoints}
            isDashed={true}
            id={new Date().getTime()}
          />
          {currentAllPointsData?.map((item: any, index: number) => {
            if (item?.type === "check_point") {
              return (
                <Marker
                  key={`check_point-${item?.index}-${selectedPoint?.id}`}
                  coordinates={item?.coord}
                  icon={
                    <DefaultMapMarker
                      number={item?.index}
                      filled
                      selected={item?.id === selectedPoint?.id}
                    />
                  }
                  onMarkerClick={() => {
                    setSelectedPoint(
                      routeRetrieveData?.data?.route_parts[index]
                    );
                    setOpenPointDetail(true);
                  }}
                />
              );
            }

            if (item?.type === "plot") {
              return item?.coord?.map((subitem: any, subIndex: any) => (
                <Marker
                  onMarkerClick={() => {
                    setSelectedPoint(
                      routeRetrieveData?.data?.route_parts[index]
                    );
                    setOpenPointDetail(true);
                  }}
                  key={`plot-${item?.index}-${subIndex}-${selectedPoint?.id}`}
                  coordinates={[subitem[1], subitem[0]]}
                  icon={
                    subIndex === 0 ? (
                      <DefaultMapMarker
                        plotMarker={true}
                        plotLength={subIndex === 0 ? item?.index : null}
                        selected={item?.id === selectedPoint?.id}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          background: "#101110",
                          border: "2px #81C728 solid",
                          borderRadius: "1000px",
                          zIndex: "-1000",
                        }}
                      />
                    )
                  }
                />
              ));
            }

            return null;
          })}
        </MapContainer>
      )}

      <Stack
        sx={{
          position: "absolute",
          paddingTop: "32px",
          right: "16px",
          zIndex: 1000,
          gap: "16px",
          overflowY: "auto",
          maxHeight: "100svh",
        }}
      >
        <Stack
          direction="column"
          gap="4px"
          sx={{
            marginBottom: "24px",
          }}
        >
          <h2 className={styles.partsTitle}>Список этапов маршрута</h2>
        </Stack>
        {routeRetrieveData?.data.route_parts
          ?.sort(sortByPriority)
          ?.map((routePart: any, index: number) => (
            <PlotPoint
              handleClick={() => {
                setSelectedPoint(routePart);
                setOpenPointDetail(true);
              }}
              previewImage={routePart?.check_point?.media?.[0]?.media}
              audio={
                routePart?.check_point?.audio_guide ??
                routePart?.plot?.audio_guide
              }
              title={routePart?.title}
              role="preview"
              index={index}
              points={routeRetrieveData?.data.route_parts?.sort(sortByPriority)}
            />
          ))}
      </Stack>
      <PointDetailDrawer
        activeIndex={activeIndex}
        handleActiveIndex={setActiveIndex}
        handleImagePreviewModal={setOpenPreviewModal}
        open={openPointDetail}
        handleClose={() => {
          setOpenPointDetail(false);
        }}
        selectedPoint={selectedPoint}
        data={routeRetrieveData?.data.route_parts}
      />
      <ImagePreviewModal
        open={openPreviewModal}
        imagesArr={
          selectedPoint?.type_of_route === "plot"
            ? selectedPoint?.plot?.media
            : selectedPoint?.check_point?.media
        }
        activeIndex={activeIndex}
        handleActiveIndex={setActiveIndex}
        handleClose={() => setOpenPreviewModal(false)}
      />
    </>
  );
};
