import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchWithAuthorization } from "../fetchWithAutorization";

export const useGetToursMap = ({
  circleCoord,
  circleRadius,
  tourExpenses,
  tourDuration,
  enabled,
}: {
  circleCoord: {
    lat: number;
    lng: number;
  };
  enabled?: boolean;
  circleRadius: number;
  tourExpenses?: Array<Number>;
  tourDuration?: any;
}) => {
  const { data, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ["tours-list", tourDuration, circleCoord],
    queryFn: async ({ pageParam }) => {
      const res = await fetchWithAuthorization(
        `trips/?page=${pageParam}&per_page=200&distance=lat=${
          circleCoord?.lat
        };lon=${circleCoord?.lng};rad=${circleRadius * 1000}${tourDuration}`,
        {
          method: "GET",
        }
      );

      if (res.ok) {
        return res.json();
      }

      throw new Error();
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: {
      meta: {
        next_url: string;
        page: number;
      };
    }) => {
      if (!lastPage.meta.next_url) return undefined;
      return lastPage.meta.page + 1;
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data?.pages.map((page: any) => page?.data)?.flat();
    },
  });
  return {
    data,
    fetchNextPage,
    refetch,
  };
};
