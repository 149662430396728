import { Box, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./PointDetailDrawer.module.css";
import { Divider } from "../../../ui-kit/Divider/Divider";
import AudioPlayer from "../../../ui-kit/Audio/Audio";
import { ImagePreviewModal } from "../../../Modals/ImagePreviewModal/ImagePreviewModal";

const PointDetailDrawer = ({
  open,
  handleClose,
  selectedPoint,
  data,
  handleImagePreviewModal,
  handleActiveIndex,
  activeIndex,
  openPreviewModal,
}: any) => {
  const currentIndex = data
    ?.map((item: any) => item.id)
    ?.indexOf(selectedPoint?.id);

  return (
    <Box
      className={styles.pointDetailWrapper}
      sx={{
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.65) 35.41%,
         rgba(0, 0, 0, 0.90) 74.91%), url(${selectedPoint?.check_point?.media?.[0]?.media?.media?.original}) lightgray 50% / cover no-repeat`,
        transform: open ? "translateX(0)" : "translateX(368px)",
        transitionDuration: "0.5s",
      }}
    >
      <Stack direction="column" gap="16px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h5 className={styles.pointDetailHeadingTitle}>
            Текущий этап{" "}
            <span
              style={{
                opacity: "0.6",
              }}
            >{`(${currentIndex + 1}/${data?.length})`}</span>
          </h5>
          <CloseIcon
            onClick={() => {
              handleClose();
            }}
            sx={{
              height: "16px",
              width: "16px",
              color: "#a1b670",
              cursor: "pointer",
            }}
          />
        </Stack>
        <Divider
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            background: "var(--Surface-Tag, rgba(137, 160, 83, 0.20))",
          }}
        />
        <h2
          className={styles.pointDetailTitle}
          style={{
            width: "256px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {selectedPoint?.title}
        </h2>
        <Stack
          direction="row"
          gap="8px"
          sx={{
            maxWidth: "270px",
            overflowX: "scroll",
            paddingBottom: "8px",
          }}
          id="custom_scroll"
        >
          {selectedPoint?.check_point?.media?.map(
            (media: any, index: number) => {
              return (
                <Stack
                  onClick={() => {
                    handleImagePreviewModal(true);
                    handleActiveIndex(index);
                    handleClose();
                  }}
                  sx={{
                    minWidth: "48px",
                    minHeight: "48px",
                    objectFit: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    background: `url(${media?.media?.media?.original}) lightgray 50% / cover no-repeat`,
                  }}
                />
              );
            }
          )}
        </Stack>
        {(!!selectedPoint?.plot?.audio_guide ||
          !!selectedPoint?.check_point?.audio_guide) && (
          <Stack>
            <p className={styles.pointDetailSubtitle}>Аудиогид:</p>
            <Stack
              sx={{
                marginTop: "-8px",
              }}
            >
              <AudioPlayer
                key={new Date().getTime()}
                audioGuide={
                  selectedPoint?.type_of_route === "plot"
                    ? selectedPoint?.plot?.audio_guide?.media?.original
                    : selectedPoint?.check_point?.audio_guide?.media?.original
                }
                isPreview
              />
            </Stack>
          </Stack>
        )}
        <Stack>
          <p
            className={styles.pointDetailDescription}
            id="custom_scroll"
            style={{
              maxHeight: "50vh",
              overflowY: "auto",
            }}
          >
            {selectedPoint?.check_point?.description}
          </p>
        </Stack>
      </Stack>
      <ImagePreviewModal
        open={openPreviewModal}
        imagesArr={
          selectedPoint?.type_of_route === "plot"
            ? selectedPoint?.plot?.media
            : selectedPoint?.check_point?.media
        }
        activeIndex={activeIndex}
        handleActiveIndex={handleActiveIndex}
        handleClose={() => handleImagePreviewModal(false)}
      />
    </Box>
  );
};

export default PointDetailDrawer;
